<template>
    <div class="MyProfile">
        <div class="MyProfile-head">
            <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div class="MyProfile-content">
            <h5>基本信息</h5>
            <div class="MyProfile-basic">
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>头像:</span>
                        <span class="img">
                            <img src="../assets/images/椭圆形_copy_u1052.svg" />
                        </span>
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>姓名:</span>
                        <input  type="text" v-model="name"/>
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>性别:</span>
                        <input  type="text" v-model="sex"/>
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>身份证号:</span>
                        <input  type="text" v-model="idCard"/>
                        <!-- <span class="img">342523197711212848</span> -->
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>出生日期:</span>
                        <input type="text" v-model="birthday" />
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>手机号码:</span>
                        <input type="text" v-model="phone" />
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
                <div class="MyProfile-common">
                    <div class="avater-common touxiang">
                        <span>地区:</span>
                        <input type="text" v-model="address" />
                    </div>
                    <div class="avater-common">
                        <i class="iconfont icon-jinrujiantou"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn">
            <button @click="goEncounterMgmt()">确定</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyProfile',
    data() {
        return {
            name: '张三',
            idCard: '342523197711212848',
            birthday: '1997-11-21',
            phone: '18355991437',
            address: '上海市 嘉定区',
            sex: '男'
        }
    },
    methods: {
        goHistotry() {
            this.$router.go(-1)
        },
        goEncounterMgmt() {
            this.$router.push('/EncounterMgmt')
        }
    },
}
</script>

<style lang="less" scoped>
.MyProfile-head{
    height: 44px;
    line-height: 44px;
    background: rgba(0,129,255);
}
.MyProfile-head .icon-fanhuijiantou {
    font-size: 20px;
    color: #fff;
    padding-left: 10px;
}
.MyProfile-common {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    background: #fff;
    box-sizing: border-box;
    margin-bottom:3px;
}
.avater-common .icon-jinrujiantou {
    color: #ddd;
    font-size: 30px;
    vertical-align: middle;
    padding-right: 15px;
}
.avater-common span{
    display: inline-block;
}
.avater-common span:first-child {
    width:86px;
    text-align: right;
    line-height: 50px;
}
.MyProfile-content h5 {
    height: 45px;
    line-height: 45px;
    padding-left: 10px;
    font-size: 17px;
}
.touxiang {
    display: flex;
    flex-direction: row;
}
.touxiang .img {
    padding-left: 6px;
}
.touxiang .img img{
    vertical-align: middle;
}
.avater-common input {
    border:none;
    margin-left: 6px;
}
.btn {
    position: absolute;
    bottom:0;
    left:0;
    right: 0;
    height: 40px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
}
.btn button {
    border:none;
    background: rgba(0,153,255);
    width: 350px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
}
</style>